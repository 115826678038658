import React, { createContext, useContext, useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import { APPLICATION_STATUS, USER_TYPES } from '../utils/constants';
import app from '../utils/firebase';

const AuthContext = createContext();

const AgentAuthProvider = ({ children }) => {
  const db = getFirestore(app);
  const storage = getStorage(app);

  const [applicationData, setApplicationData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [startedApplications, setStartedApplications] = useState([]);
  const [preQualifiedApplications, setPreQualifiedApplications] = useState([]);
  const [approvedApplications, setApprovedApplications] = useState([]);
  const [
    conditionallyApprovedApplications,
    setConditionallyApprovedApplications,
  ] = useState([]);
  const [rejectedApplications, setRejectedApplications] = useState([]);
  const [pausedApplications, setPausedApplications] = useState([]);
  const [moreInfoRequired, setMoreInfoRequired] = useState([]);
  const [inreview, setInreview] = useState([]);

  const [inactive, setInactive] = useState([]);
  const [followUpClients, setFollowUpClients] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [closingsData, setClosingsData] = useState([]);
  const [invitedAgents, setInvitedAgents] = useState([]);
  const [accordianIndex, setAccordianIndex] = useState(null);
  const [initiatedOffers, setInitiatedOffers] = useState([]);

  //search
  const [searchResults, setSearchresults] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth(app);

      onAuthStateChanged(auth, async user => {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            let data = docSnap.data();

            if (data.type == USER_TYPES.AGENT) {
              getUserApplication(user.uid);
            }
          } else {
          }
        } else {
        }
      });
    };

    fetchData().catch(console.error);
  }, []);

  const handleSubmitApplication = async applicationResponses => {
    try {
      const auth = getAuth(app);
      setAuthLoading(true);

      await setDoc(
        doc(db, 'agent_application_responses', auth.currentUser.uid),
        {
          uid: applicationResponses.uid,
          stepOne: applicationResponses.stepOne,
          stepTwo: applicationResponses.stepTwo,
          stepThree: applicationResponses.stepThree,
          timeSubmitted: Timestamp.now(),
        }
      );

      const usersRef = doc(db, 'agents', auth.currentUser.uid);
      await updateDoc(usersRef, {
        applicationCompleted: true,
        legalName: {
          firstName: applicationResponses.legalName.firstName,
          lastName: applicationResponses.legalName.lastName,
        },
      });
      updateInvitationStatus(auth.currentUser.email?.toLowerCase(), 'joined');

      window.location.reload(false);

      return {};
    } catch (err) {
      setAuthLoading(false);
    }
  };

  const updateAgentFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(db, 'agents', auth.currentUser.uid);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const getUserApplication = async userId => {
    try {
      const docRef = doc(db, 'agent_application_responses', userId);
      const docSnap = await getDoc(docRef);
      setApplicationData(docSnap.data());
      return docSnap.data();
    } catch (err) {
      console.log(
        '🚀 ~ file: AuthContext.js ~ line 327 ~ getUserApplication ~ err',
        err
      );
    }
  };

  const updateApplicationFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(db, 'agent_application_responses', auth.currentUser.uid);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const changeUserProfileImage = async image => {
    const auth = getAuth(app);
    const profileImageRef = ref(
      storage,
      `agent_application_files/${auth.currentUser.uid}/profile/avatar`
    );

    const response = await uploadBytes(profileImageRef, image);
    const imageUrl = await getDownloadURL(response.ref);
    return imageUrl;
  };

  const getAllAgentUsers = async agentInvites => {
    try {
      let data = [];
      const clientRef = collection(db, 'users');

      const q = query(clientRef);

      const querySnapshot = await getDocs(q);
      querySnapshot.docs.forEach(doc => {
        data.push({
          uid: doc.id,
          ...doc.data(),
        });
      });

      let newAgentInvites = [];
      for (let i = 0; i < agentInvites.length; i++) {
        let isUsed = false;
        for (let j = 0; j < data.length; j++) {
          if (agentInvites[i].clientEmail === data[j].email) {
            isUsed = true;
            break;
          }
        }

        if (!isUsed) {
          newAgentInvites.push(agentInvites[i]);
        }
      }

      return newAgentInvites;

      // return [];
    } catch (err) {
      console.log(err);
    }
  };

  const getAgentInvitedUsers = async () => {
    const auth = getAuth(app);

    let data = [];
    const clientRef = collection(db, 'agent_invites');

    const q = query(
      clientRef,
      where('agent', '==', auth.currentUser.uid),
      where('isRejected', '==', false)
    );
    // TODO: For testing
    // const q = query(
    //   clientRef,
    //   where('agent', '==', 'PGcvVX0HyHd4GOtv7lqHreztHhz1'),
    //   where('isRejected', '==', false)
    // );

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      if (!doc.data()?.isAgent) {
        data.push(doc.data());
      }
    });

    const newData = await getAllAgentUsers(data);

    return newData;
  };

  const getInvitedAgents = async () => {
    const auth = getAuth(app);
    const data = []; // Use a Set to ensure unique clientEmail values

    const clientRef = collection(db, 'agent_invites');
    const userRef = collection(db, 'users');

    const q = query(
      clientRef,
      where('agent', '==', auth.currentUser.uid),
      where('isRejected', '==', false),
      where('isAgent', '==', true)
    );

    const usersQuery = query(
      userRef,
      where('agent', '==', auth.currentUser.uid),
      where('type', '==', 'agent')
    );

    const [querySnapshot, usersQuerySnapshot] = await Promise.all([
      getDocs(q),
      getDocs(usersQuery),
    ]);

    querySnapshot.docs.forEach(doc => {
      data.push(doc.data());
    });

    usersQuerySnapshot.docs.forEach(doc => {
      const userData = doc.data();
      const clientEmail = userData.email;

      const clientEmailIndex = data.findIndex(
        invites => invites.clientEmail === clientEmail
      );
      if (clientEmailIndex < 0) {
        data.push({
          agent: userData.agent,
          clientEmail: clientEmail,
          clientName:
            userData.legalName.firstName + ' ' + userData.legalName.lastName,
          clientResponded: true,
          isAgent: true,
          isClientInvite: false,
          isRejected: false,
          status: 'joined',
          createdAt: userData.account?.accountCreated,
        });
      }
    });

    // Convert Set to Array and return
    return [...data];
  };

  const updateInvitationStatusToRejected = async (clientEmail, agentId) => {
    const constraints = [];

    // const clientInvite = await getInvitationByClientEmail(clientEmail);

    if (Boolean(agentId)) {
      constraints.push(where('agent', '!=', agentId));
    }

    constraints.push(where('clientEmail', '==', clientEmail));

    const clientRef = collection(db, 'agent_invites');
    const q = query(clientRef, ...constraints);
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async doc => {
      updateDoc(doc.ref, {
        isRejected: true,
      });
    });

    return 'success';
  };

  const updateUserStatus = async (userId, status) => {
    const docRef = doc(db, 'users', userId);

    await updateDoc(docRef, {
      status_by_agent: status,
    });

    return 'success';
  };

  const getInviteAcceptedUsers = async () => {
    const auth = getAuth(app);
    let data = [];
    const clientRef = collection(db, 'users');

    const q = query(clientRef, where('agent', '==', auth.currentUser.uid));

    // TODO: For Testing
    // const q = query(
    //   clientRef,
    //   where('agent', '==', 'PGcvVX0HyHd4GOtv7lqHreztHhz1')
    // );

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({
        uid: doc.id,
        ...doc.data(),
      });
    });

    return data;
  };

  const getInvitedUserApplication = async userId => {
    const docRef = doc(db, 'homebuyer_application_responses', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  const getAgentApplication = async userId => {
    const docRef = doc(db, 'agent_application_responses', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  const getAgentInvitesData = async callback => {
    try {
      const allInvitedUsers = await getInvitedAgents();
      const promises = allInvitedUsers.map(async invite => {
        const { user, application } = await getAgentDataByEmail(
          invite.clientEmail
        );
        const userName = user?.legalName
          ? user.legalName.firstName + ' ' + user.legalName.lastName
          : invite.clientName;
        const lastLogin = user?.lastLogin
          ? moment(user.lastLogin.toDate()).fromNow()
          : 'Unknown';
        const status = application?.applicationCompleted
          ? 'Joined'
          : user
          ? 'Invited'
          : 'Invited';

        return {
          uid: user?.uid,
          name: userName,
          inviteSent: moment(invite.createdAt.toDate()).fromNow(),
          createdAt: invite.createdAt.toDate(),
          email: invite.clientEmail,
          user,
          isInvite: false,
          lastLogin,
          isReminderEnabled: status === 'Invited',
          applicationStatus: status,
        };
      });

      const response = await Promise.all(promises);
      response.sort((a, b) => b.createdAt - a.createdAt);

      setInvitedAgents(response);

      if (callback) {
        callback();
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const updateApplicationsDataV2 = async callback => {
    try {
      let allData = [];
      let statuses = {
        pending: [],
        rejected: [],
        approved: [],
        moreInfo: [],
        paused: [],
        inReview: [],
        conditionallyApproved: [],
        offerExtended: [],
        offerAccepted: [],
        inspectionComplete: [],
        preclosingComplete: [],
        offerClosed: [],
        inactive: [],
        preQualified: [],
        invited: [],
        startedApplications: [],
        moreInfoRequired: [],
      };

      let [allInvitedUsers, invites] = await Promise.all([
        getInviteAcceptedUsers(),
        getAgentInvitedUsers(),
      ]);

      const allInvitedUserEmails = allInvitedUsers.map(user => user.email);
      const inviteEmails = invites.map(invite => invite.clientEmail);

      const missingEmails = allInvitedUserEmails.filter(
        email => !inviteEmails.includes(email)
      );

      missingEmails.forEach(email => {
        invites.push({ clientEmail: email });
      });

      invites = invites.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );

      await Promise.all(
        invites.map(async invite => {
          const { user, application } = await getUserbyEmail(
            invite.clientEmail
          );

          let userName = user?.legalName
            ? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
            : invite?.clientName;

          let lastLogin = user?.lastLogin
            ? moment(user?.lastLogin?.toDate()).fromNow()
            : 'Unknown';

          let qualifiedAmount =
            user?.qualifiedAmount > 0
              ? Number(user?.qualifiedAmount)?.toLocaleString()
              : application?.stepEight
              ? application.stepEight[0]?.answer
              : 'unknown';

          const startDate = user?.applicationStartDate
            ? user?.applicationStartDate?.toDate()
            : application?.timeSubmitted
            ? application?.timeSubmitted?.toDate()
            : user?.lastLogin
            ? user?.lastLogin?.toDate()
            : invite?.createdAt?.toDate();

          let formattedData = {
            uid: user?.uid,
            name: userName,
            applicationStartDate: startDate ? moment(startDate).fromNow() : '',
            startDate: startDate,
            inviteSent: moment(invite?.createdAt?.toDate()).fromNow(),
            createdAt: invite?.createdAt?.toDate(),
            email: invite.clientEmail,
            user,
            application,
            action: 'delete',
            interaction: 'Add',
            isInvite: false,
            lastLogin,
            numReminders: invite?.numReminders,
            qualifiedAmount,
          };

          const currentStatus = application?.status;
          const statusName = getStatus(currentStatus);

          if (user) {
            if (currentStatus && currentStatus !== 'started') {
              formattedData = {
                ...formattedData,
                applicationStatus: statusName,
              };

              statuses[currentStatus].push(formattedData);
            } else if (user?.applicationCompleted) {
              formattedData = {
                ...formattedData,
                applicationStatus: 'Prequalified',
              };
              statuses.preQualified.push(formattedData);
            } else if (!user?.applicationCompleted) {
              formattedData = {
                ...formattedData,
                applicationStatus: 'Started',
              };
              statuses.startedApplications.push(formattedData);
            }
          } else {
            formattedData = {
              ...formattedData,
              applicationStatus: 'Invited',
            };

            statuses.invited.push(formattedData);
          }

          allData.push(formattedData);
        })
      );

      setInactive(statuses.inactive);

      const sortedPrequalified = statuses.preQualified.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );

      setPreQualifiedApplications([...sortedPrequalified]);

      const sortedStarted = statuses.startedApplications.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );

      setStartedApplications(sortedStarted);
      setApprovedApplications(statuses.approved);
      setConditionallyApprovedApplications(statuses.conditionallyApproved);
      setOffersData({
        offerAccepted: statuses.offerAccepted,
        offerExtended: statuses.offerExtended,
        inreview: statuses.inReview,
        moreInfoRequired: statuses.moreInfoRequired,
      });
      setClosingsData({
        inspectionComplete: statuses.inspectionComplete,
        preclosingComplete: statuses.preclosingComplete,
        offerClosed: statuses.offerClosed,
      });

      setInvitedUsers(statuses.invited);
      setRejectedApplications(statuses.rejected);
      setPausedApplications(statuses.paused);

      setAllUsers(allData);

      callback && callback();
      return { allData, statuses };

      //if user found push
    } catch (err) {
      console.log(
        '🚀 ~ file: AgentContext.js:443 ~ updateApplicationsDataV2 ~ err:',
        err
      );
      callback && callback();
    }
  };

  const updateAllApplicationsData = async callback => {
    try {
      getAgentInvitesData();
      getinitiatedOffersData();
      return await updateApplicationsDataV2(callback);
    } catch (err) {
      callback && callback();
    }
  };

  const getUserbyEmail = async email => {
    let data = [];
    const clientRef = collection(db, 'users');
    const q = query(clientRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let user = data[0];
    let application;

    if (user) {
      application = await getInvitedUserApplication(user.uid);
    }

    let final = {
      user,
      application,
    };

    return final;
  };

  const getAgentDataByEmail = async email => {
    let data = [];
    const clientRef = collection(db, 'users');
    const q = query(clientRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    let user = data[0];
    let application;

    if (user) {
      application = await getAgentApplication(user.uid);
      // application = await getAgentApplication('080EJg55sCffWgBNHPBRDbYDOOC3');
    }

    let final = {
      user,
      application,
    };

    return final;
  };

  const updateAllApplicationsSectionData = async () => {
    const invites = await getAgentInvitedUsers();

    let inviteData = [];
    let followUpData = [];
    for (let invite of invites) {
      let userDetails = await getUserbyEmail(invite.clientEmail?.toLowerCase());

      let applicationStatus = 'Invited';

      if (userDetails.application) {
        const currentStatus = userDetails?.application?.status;
        if (currentStatus === 'approved') {
          applicationStatus = 'Approved';
        }
      } else if (
        userDetails?.user?.applicationStarted &&
        !userDetails?.user?.applicationCompleted
      ) {
        applicationStatus = 'Started';
      } else if (userDetails?.user?.applicationCompleted) {
        applicationStatus = 'Completed';
      }

      let lastLogin = userDetails.user?.lastLogin
        ? moment(userDetails?.user?.lastLogin?.toDate()).fromNow()
        : 'Unknown';

      let data = {
        name: invite.clientName,
        email: invite.clientEmail,
        inviteSent: moment(invite?.createdAt.toDate()).fromNow(),
        createdAt: moment(invite?.createdAt?.toDate()).fromNow(),
        action: 'delete',
        interaction: 'Add',
        numReminders: invite.numReminders,
        original: invite,
        applicationStatus,
        isInvite: true,
        lastLogin,
      };

      if (!userDetails.user || !userDetails?.application) {
        followUpData.push(data);
      }

      inviteData.push(data);
    }

    // setInvitedUsers(inviteData);
    setFollowUpClients(followUpData);
  };

  const getInvitationByClientEmail = async email => {
    let data = [];
    const clientRef = collection(db, 'agent_invites');
    const q = query(clientRef, where('clientEmail', '==', email));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });

    return data[0];
  };

  const getSingleApplicationDetails = async userEmail => {
    const userDetails = await getUserbyEmail(userEmail?.toLowerCase());

    const user = userDetails.user;

    const invitation = await getInvitationByClientEmail(userEmail);

    if (!user) {
      return {
        email: invitation.clientEmail,
        name: invitation.clientName,
        lastLogin: 'Unknown',
        phone: 'Unknown',
        prequalifiedAmount: 'Unknown',
        status: 'Invited',
        currentStatus: 'Invited',
        application: null,
        user: null,
      };
    }

    const { application } = userDetails;

    let originalStatus;
    let status;
    let lastLogin;
    let userName,
      phone = application?.stepOne[0]
        ? application?.stepOne[0]?.answer
        : 'Unknown';

    if (application && application.status) {
      originalStatus = application.status;
      status = getStatus(application.status);
      phone = application.stepOne[0]?.answer;
    } else if (user.applicationCompleted) {
      originalStatus = 'Prequalified';
      status = 'Prequalified';
    } else if (!user.applicationCompleted) {
      originalStatus = 'Started';
      status = 'Started';
    } else {
      originalStatus = 'Invited';
      status = 'Invited';
    }

    if (user.lastLogin) {
      lastLogin = moment(user.lastLogin.toDate()).fromNow();
    } else {
      lastLogin = 'Unknown';
    }

    if (user.legalName) {
      userName = `${user.legalName.firstName} ${user.legalName.lastName}`;
    } else if (invitation) {
      userName = invitation.clientName;
    } else {
      userName = 'Unknown';
    }

    if (
      originalStatus === APPLICATION_STATUS.IN_REVIEW ||
      originalStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
    ) {
      originalStatus = 'Prequalified';
    }

    return {
      email: user.email,
      name: userName,
      lastLogin,
      phone: phone,
      prequalifiedAmount: user.qualifiedAmount,
      status: originalStatus,
      currentStatus: status,
      application,
      user,
    };
  };

  const saveAgentInteraction = async (clientEmail, interactionText) => {
    const auth = getAuth(app);

    const agentRef = doc(
      db,
      `interactions/${
        auth.currentUser.uid
      }/${clientEmail?.toLowerCase()}/${Date.now()}`
    );
    await setDoc(agentRef, {
      description: interactionText,
      createdAt: Timestamp.now(),
    });
  };

  const getInteractionsByClient = async clientEmail => {
    const auth = getAuth(app);

    const interactionRef = collection(
      db,
      'interactions',
      auth.currentUser.uid,
      clientEmail?.toLowerCase()
    );

    const interactionsSnap = await getDocs(interactionRef);

    let data = interactionsSnap.docs.map(d => ({
      id: d.id,
      ...d.data(),
      createdAt: d.data().createdAt?.toDate(),
    }));

    data = data.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });

    return data;
  };

  const getStatus = applicationStatus => {
    let status;

    switch (applicationStatus) {
      case APPLICATION_STATUS.APPROVED:
        status = 'Approved';
        break;
      case APPLICATION_STATUS.CONDITIONALLY_APROVED:
        status = 'Conditionally Approved';
        break;
      case APPLICATION_STATUS.REJECTED:
        status = 'Rejected';
        break;
      case APPLICATION_STATUS.MORE_INFO_REQUIRED:
        status = 'Prequalified';
        break;
      case APPLICATION_STATUS.PAUSED:
        status = 'Paused';
        break;
      case APPLICATION_STATUS.IN_REVIEW:
        status = 'Prequalified';
        break;
      case APPLICATION_STATUS.OFFER_EXTENDED:
        status = 'Offer Extended';
        break;
      case APPLICATION_STATUS.OFFER_ACCEPTED:
        status = 'Offer Accepted';
        break;
      case APPLICATION_STATUS.INSPECTION_COMPLETE:
        status = 'Inspection Complete';
        break;
      case APPLICATION_STATUS.PRECLOSING_COMPLETE:
        status = 'Preclosing Complete';
        break;
      case APPLICATION_STATUS.OFFER_CLOSED:
        status = 'Offer Closed';
        break;
      case APPLICATION_STATUS.INACTIVE:
        status = 'Inactive';
        break;
      default:
        status = 'Invited';
    }
    return status;
  };

  const uploadOfferInitiationFiles = async (images, type, offerId) => {
    const auth = getAuth(app);
    // const storageRef = storage.ref();

    if (type !== 'sales' && type !== 'rental') {
      console.error('invalid files type');
      return;
    }

    const uploadAndFetchDownloadURL = async (image, index) => {
      const profileImageRef = ref(
        storage,
        `offer_initiation_files/${auth.currentUser.uid}/${offerId}/${type}/${image.name}`
      );

      const response = await uploadBytes(profileImageRef, image);
      const imageUrl = await getDownloadURL(response.ref);
      return imageUrl;
    };

    const uploadPromises = images.map((image, index) =>
      uploadAndFetchDownloadURL(image, index)
    );

    const imageUrls = await Promise.all(uploadPromises);
    return imageUrls;
  };

  const saveOfferDetails = async payload => {
    const agentRef = collection(db, 'offers');
    const response = await addDoc(agentRef, payload);

    return response;
  };

  const updateOffer = async (updatedField, docId) => {
    const docRef = doc(db, 'offers', docId);
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const getinitiatedOffersData = async callback => {
    const auth = getAuth(app);
    let data = [];
    const clientRef = collection(db, 'offers');

    const q = query(clientRef, where('agentId', '==', auth.currentUser.uid));

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      const docData = doc.data();
      data.push({
        uid: doc.id,
        ...docData,
        propertyListPrice: docData.propertyListPrice?.toLocaleString(),
        propertyOfferPrice: docData.propertyOfferPrice?.toLocaleString(),
      });
    });

    setInitiatedOffers(data);
    callback && callback();
    return data;
  };

  const updateInvitationStatus = async (userEmail, status) => {
    const clientRef = collection(db, 'agent_invites');
    const q = query(clientRef, where('clientEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);

    querySnapshot.docs.forEach(async doc => {
      updateDoc(doc.ref, {
        status: status,
      });
    });
  };

  const getUserOnlybyEmail = async emails => {
    const clientRef = collection(db, 'users');
    const queries = emails.map(email =>
      query(clientRef, where('email', '==', email))
    );

    const querySnapshots = await Promise.all(queries.map(q => getDocs(q)));

    const data = [];

    querySnapshots.forEach(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        data.push({ ...doc.data(), uid: doc.id });
      });
    });

    return data;
  };

  const updateOneTimeInviteStatuses = async () => {
    const clientRef = collection(db, 'agent_invites');
    const querySnapshot = await getDocs(clientRef);

    const batchSize = 500; // Maximum number of writes per batch
    const totalDocs = querySnapshot.docs.length;
    let processedDocs = 0;

    while (processedDocs < totalDocs) {
      const batch = writeBatch(db);
      const batchDocs = querySnapshot.docs.slice(
        processedDocs,
        processedDocs + batchSize
      );

      const emails = batchDocs.map(doc => doc.data().clientEmail);
      const userDetails = await getUserOnlybyEmail(emails);

      for (let i = 0; i < batchDocs.length; i++) {
        const doc = batchDocs[i];
        const docDetails = doc.data();
        const email = docDetails.clientEmail;
        const user = userDetails.find(user => user.email === email);

        const status = user?.applicationCompleted ? 'joined' : 'invited';

        batch.update(doc.ref, { status });
      }

      await batch.commit();
      processedDocs += batchSize;
      console.log(
        '🚀 ~ file: AgentContext.js:912 ~ updateOneTimeInviteStatuses ~ processedDocs:',
        processedDocs
      );
    }
  };

  const createAgentEmailPreferences = async id => {
    try {
      const q = query(collection(db, 'agent_invites'));

      const querySnapshot = await getDocs(q);

      let inviteList = [];
      querySnapshot.forEach(doc => {
        inviteList.push(doc.id);
      });

      for (let i = 0; i < inviteList.length; i++) {
        const docRef = doc(db, 'agent_invites', inviteList[i]);

        await updateDoc(docRef, {
          emailPreferences: {
            disableMarketingEmails: false,
          },
        });

        console.log('Agent Email Preferences Created for: ', inviteList[i]);
      }

      console.log('<=== Agent Email Preferences Created ===>');
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const value = {
    setAccordianIndex,
    updateUserStatus,
    setSearchresults,
    handleSubmitApplication,
    updateApplicationFields,
    updateAgentFields,
    getUserApplication,
    changeUserProfileImage,
    getAgentInvitedUsers,
    updateInvitationStatusToRejected,
    updateAllApplicationsData,
    updateInvitationStatus,
    getInvitedUserApplication,
    updateAllApplicationsSectionData,
    getSingleApplicationDetails,
    saveAgentInteraction,
    getInteractionsByClient,
    getStatus,
    getAgentInvitesData,
    uploadOfferInitiationFiles,
    saveOfferDetails,
    updateOffer,
    getinitiatedOffersData,
    invitedUsers,
    startedApplications,
    preQualifiedApplications,
    approvedApplications,
    followUpClients,
    offersData,
    closingsData,
    conditionallyApprovedApplications,
    accordianIndex,
    authLoading,
    applicationData,
    searchResults,
    inactive,
    allUsers,
    rejectedApplications,
    pausedApplications,
    invitedAgents,
    initiatedOffers,
    createAgentEmailPreferences,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAgentAuth = () => {
  return useContext(AuthContext);
};

export { AgentAuthProvider, useAgentAuth };
